import {oneFormAction, formAction} from '../modules/form';

;( $=>{
	const
		log = console.log,
		_d = $(document),
		_w = $(window),
		load_windows = ()=>{
		};
	_w.on('load',load_windows);
	_d.ready(()=>{

		let
			_ssoh, // scrollspy.outerHeight()
			_ww, _wh, _ws,
			_ssmt = 20, // scrollspy margin-top
			_sspt = 70, // scrollspy padding-top
			_ss = $('.scrollspy'),
			_ssot, // scrollspy.offset().top
			_dno_ot,
			_crishka,
			iziModal = $('.iziModal'),
			header = $('#header'),
			main = $('#main'),
			footer = $('#footer'),
			scrollspy = function(){
				if( _ss.length ){
					_ssoh = _ss.outerHeight();
					_crishka = _ws + _ssmt;
					if( _ww > 992 && _crishka > _ssot ){
						if( _crishka > ( _dno_ot - _ssoh ) ) _ss.removeClass('posf').addClass('posr').css({top:(_dno_ot - ( _ssoh + _ssot ))});
						else _ss.removeClass('posr').addClass('posf').css({top:_ssmt});
					}else _ss.removeClass('posf posr').css({top:''});
				}
			},
			handleResize = ()=>{
				_ww = _w.width(),
				_wh = _w.height();

				main.css({minHeight:_wh});
				if( _wh > 500 ) $('.fullscreen-h').innerHeight( _wh );
				else $('.fullscreen-h').removeAttr('style');
				if( _ww > 600 ){
					$('.js-full-h').each(function(){
						let _ = $(this);
						_.height(_.parents('.wr-js-full-h').height());
					});
					main.css({paddingBottom:footer.outerHeight()});
				}else{
					$('.js-full-h').height('');
					main.css({paddingBottom:''});
				}
				if( _ss.length ){
					if( _ww > 992 ) _ss.css('width',_ss.parent().width() );
					else _ss.css('width','');
				}
				/*For iziModal*/
				if( _wh < 660 ) iziModal.addClass('min-height');
				else iziModal.removeClass('min-height');
			},
			handleScroll = ()=>{
				_ws = _w.scrollTop();
				if( _ws > 200 ) $('#moveup').removeClass('scale-out');
				else $('#moveup').addClass('scale-out');
				if( _ss.length ){
					_ssot = _ss.parents('.wr-scrollspy').offset().top;
					_dno_ot = $('.dno-scrollspy').offset().top,
					scrollspy();
				}
			},
			thunk = _ => {
				if( _.hasClass( 'has-catalog' ) ) location.href='/thanks-catalog/';
				else location.href='/thanks/';
			},
			files = null;
		/*handleResize init*/
		handleResize();
		_w.resize(handleResize);
		/*handleScroll init*/
		handleScroll();
		_w.scroll(handleScroll);

		$('.form').each(function(){
			formAction( $(this), thunk, files );
		});

		$('.btn-slide').click(function(e){
			e = e || window.event;
			e.stopPropagation();
			e.preventDefault();
			$( $(this).toggleClass('active').attr('href') ).slideToggle().parents('.hide-text').toggleClass('active');
		});


		/*Link-move init*/
		$('.link-move').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':$( $(this).attr('href') ).offset().top-20},"slow");
		});

		$('.lazy').lazy({
			effect: "fadeIn",
			effectTime: 2000,
			threshold: 0,
			enableThrottle: true,
			throttle: 250,
			delay: 2000
		});


/*start Test*/
		let
			answer = [],
			imageStore = {
				step1: {
					floor2: [
						['Проект «56-13»','test-do100-floor2-1.jpg'],
						['Проект «Сосед»','test-do100-floor2-2.jpg'],
						['Проект «53-89»','test-do100-floor2-3.jpg'],
					],
					floor3: [
						['Проект «61-42»','test-do100-floor3-1.jpg'],
						['Проект «62-40»','test-do100-floor3-2.jpg'],
						['Проект «62-39»','test-do100-floor3-3.jpg'],
					],
				},
				step2: {
					floor2: [
						['Проект «57-39»','test-ot101do150-floor2-1.jpg'],
						['Проект «55-28»','test-ot101do150-floor2-2.jpg'],
						['Проект «53-88»','test-ot101do150-floor2-3.jpg'],
					],
					floor3: [
						['Проект «Робин»','test-ot101do150-floor3-1.jpg'],
						['Проект «Шевалье»','test-ot101do150-floor3-2.jpg'],
						['Проект «Эйваро»','test-ot101do150-floor3-3.jpg'],
					],
					floor4: [
						['Проект «Веста»','test-ot101do150-floor4-1.jpg'],
						['Проект «57-13»','test-ot101do150-floor4-2.jpg'],
						['Проект «61-87»','test-ot101do150-floor4-3.jpg'],
					],
				},
				step3: {
					floor4: [
						['Проект «Северная корона»','test-ot150-floor4-1.jpg'],
						['Проект «54-05»','test-ot150-floor4-2.jpg'],
						['Проект «Нахал»','test-ot150-floor4-3.jpg'],
					],
					floor5: [
						['Проект «R 20»','test-ot150-floor5-1.jpg'],
						['Проект «Интрига»','test-ot150-floor5-2.jpg'],
						['Проект «М 335»','test-ot150-floor5-3.jpg'],
					]
				}
			};
		let step = 1;
		$('.block input').change(function(){
			let
				_ = $(this),
				_p = _.parents('.block');
				$('#step-4').find( '#answer-'+_p.data('step') ).val(_.val());
				answer[step] = _.attr( 'id' ).replace('step-'+step+'-','');
				$( '#next' ).removeClass('error');
		});
		$( '#next' ).click( function(e){
			e = e || window.event;
			e.stopPropagation();
			e.preventDefault();
			let cur,next,imagesProjects;
			if( $('#answer-'+step).val() ){
				cur = $( '#step-' + step );
				step++;
				next = $( '#step-' + step );
				switch( parseInt( answer[1] ) ){
					case 1: $('#step-2-4,#step-2-5').parent().hide(); break;
					case 2: $('#step-2-5').parent().hide(); break;
					case 3: $('#step-2-2').parent().hide(); break;
				}
				cur.slideUp(()=>{
					next.slideDown();
					$('.step-counter .count').text(step);
					$('.progressline').addClass('step-'+step);
					if( step > 3){
						imagesProjects = imageStore[ 'step' + answer[1] ][ 'floor' + answer[2] ];
						$('.wr-projects .projects').each(function(index){
							let _ = $(this);
							_.find('.bl-img').css({
								backgroundImage: 'url( ' + pathTemplateDirectory +'/img/material/test/'+ imagesProjects[index][1] + ')'
							});
							_.find('.name').text(imagesProjects[index][0] );
						});
						$(this).fadeOut();
						$('.step-counter').text('Остался последний шаг. Заберите подарки!');
						$('.wr-projects').slideDown();
						$('.test .title').slideUp();
					}
				});
			}else $(this).addClass( 'error' );
		} );
		/*end Test*/

		/*Start Popup iziModal*/
		let _iziModalSimple = $('.iziModalSimple');
		if( _iziModalSimple.length ){
			console.log('iziModalSimple init');
			_iziModalSimple.each( function(){
				let form_effect = oneFormAction();
				$(this).iziModal({
					history: !1,
					width: 960,
					transitionIn: 'fadeInDown',
					transitionOut: 'fadeOutUp',
					bodyOverflow: !0,
					focusInput: !1,
					overlayColor: 'rgba(0,0,0,.8)',
					onOpened(modal){
						setTimeout(()=>{
							modal.$element.find('.form').each( function(){ form_effect( $(this), thunk, files ) } );
							$('.switcher').click(function(){
								if( $(this).attr('for') === 'switcher-2' ) $('.switcher-input').fadeIn();
								else $('.switcher-input').fadeOut();
							});
						},100);
					}
				});
			});
			$('.btn-popup-simple').click(function(e){
				e.preventDefault();
				$( $(this).attr('href') ).iziModal('open');
			});
		}
		let _iziProjects = $('#iziModal-projects');
		if( _iziProjects.length ){
			let url_project = '';
			console.log('iziProjects init');
			_iziProjects.iziModal({
				history: !1,
				width: 960,
				transitionIn: 'fadeInDown',
				transitionOut: 'fadeOutUp',
				bodyOverflow: !0,
				overlayColor: 'rgba(0,0,0,.8)',
				focusInput: !1,
				onOpening(modal){
					modal.startLoading();
					$.get(url_project).
						done(function(data) {
							setTimeout(()=>{
								_iziProjects.find('.wr-content').html(data);
								setTimeout(()=>{
									load_windows();
									modal.$element.find('.form').each( function(){ formAction( $(this), thunk, files ) } );
									modal.stopLoading();
								},500);
							},500);
						});
				}
			});
			$('.btn-popup-ajax').click(function(e){
				e.preventDefault();
				url_project = $(this).attr('href');
				_iziProjects.iziModal('open');
			});
		}
		/*end Popup iziModal*/

		/*ENd*/
		if( $('.ssk-group').length ) SocialShareKit.init();
	});
})(jQuery);